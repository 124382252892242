<template>
  <div class="footer-content__container">
    <a-footer-logo />

    <div class="footer-content__menu-terms">
      <a-footer-menu-block
        v-if="$helper.isNotAmpPage($route.name)"
        :menu-items="footerLeftSection"
      />

      <a-visibility
        v-if="$helper.isNotAmpPage($route.name)"
        hide
        :on="[$breakpoint.mobile]"
      >
        <div class="divider_vertical divider_vertical_sm"></div>
      </a-visibility>

      <a-footer-menu-block
        v-if="$helper.isNotAmpPage($route.name)"
        :menu-items="footerRightSection"
      />

      <a-visibility
        v-if="$helper.isNotAmpPage($route.name)"
        hide
        :on="[$breakpoint.mobile]"
      >
        <div class="divider_vertical divider_vertical_sm"></div>
      </a-visibility>

      <a-footer-socials />

      <a-visibility
        v-if="$helper.isNotAmpPage($route.name)"
        hide
        :on="[$breakpoint.mobile, $breakpoint.tablet, $breakpoint.desktopSm]"
      >
        <div class="divider_vertical divider_vertical_sm"></div>
      </a-visibility>

      <div
        v-if="$helper.isNotAmpPage($route.name)"
        class="footer-content__newsletter"
        @click="openNewsLetterModal"
      >
        Get our newsletter
      </div>
      <a-link
        v-else
        class="footer-content__newsletter"
        v-bind="linkToNonAmpPageWithNewsletterFormProps"
      >
        Get our newsletter
      </a-link>

      <a-visibility
        v-if="$helper.isNotAmpPage($route.name)"
        hide
        :on="[$breakpoint.tablet, $breakpoint.desktopSm]"
      >
        <a-footer-service
          :assistant-button-id="$options.consts.ASSISTANT_BUTTON_ID.FOOTER_2"
        />
      </a-visibility>
      <a-footer-service
        v-else
        :assistant-button-id="$options.consts.ASSISTANT_BUTTON_ID.FOOTER_2"
      />

      <a-footer-terms />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

import AFooterLogo from '../AFooterLogo'
import AFooterMenuBlock from './AFooterMenuBlock'
import AFooterSocials from './AFooterSocials'
import AFooterService from './AFooterService'
import AFooterTerms from './AFooterTerms'
import { MODAL } from 'modals/AModalWrapper'
import { ACTION_QUERY_PARAM } from '@/utils/mixins/actions-on-query-params'
import { REL_ATTRIBUTE_BACKEND_ENUM } from '@fmpedia/enums'
import { ASSISTANT_BUTTON_ID } from 'shared/AAssistantButton/enums'
import { removeAmpSuffix } from '@fmpedia/helpers'

export default {
  name: 'AFooterContent',
  components: {
    AFooterLogo,
    AFooterMenuBlock,
    AFooterSocials,
    AFooterService,
    AFooterTerms
  },
  consts: {
    ASSISTANT_BUTTON_ID
  },
  computed: {
    ...mapGetters({
      footerLeftSection: 'footerLeftSection',
      footerRightSection: 'footerRightSection'
    }),
    linkToNonAmpPageWithNewsletterFormProps() {
      return {
        to: {
          path: removeAmpSuffix(this.$route.path),
          query: { [ACTION_QUERY_PARAM.OPEN_NEWSLETTER_FORM]: true }
        },
        directive: REL_ATTRIBUTE_BACKEND_ENUM.NOFOLLOW
      }
    }
  },
  methods: {
    openNewsLetterModal() {
      this.$helper.openModal(MODAL.NEWSLETTER_FORM)
    }
  }
}
</script>

<style lang="scss" scoped>
.footer-content__container {
  width: 100%;
  max-width: 1265px;
  height: 174px;
  display: flex;
  justify-content: space-between;

  @include desktop-md {
    max-width: 1105px;
  }

  @include desktop-sm {
    height: 186px;
  }

  @include tablet {
    height: 276px;
  }

  @include mobile {
    flex-direction: column;
    align-items: center;
    height: auto;
  }

  .footer-content__menu-terms {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    padding-left: 50px;

    @include desktop-md {
      padding-left: 30px;
    }

    @include mobile {
      flex-direction: column;
      justify-content: center;
      padding-left: 0;
    }
  }
}

.footer-content__newsletter {
  display: none;

  @include mobile {
    display: block;
    margin: 16px auto 30px auto;
    font-size: 14px;
  }
}

.footer-content__advertise {
  width: 100%;
  max-width: 795px;
  text-align: center;
  margin-top: 5px;
  padding: 5px;
  font-size: 12px;
}
</style>
<style lang="scss">
.amp-layout__wrapper {
  .footer-content__container {
    flex-direction: column;
    align-items: center;
    height: auto;

    .footer-content__menu-terms {
      flex-direction: column;
      justify-content: center;
      padding-left: 0;
      align-items: center;
    }
  }

  .footer-content__newsletter {
    display: block;
    margin: 16px auto 30px auto;
    font-size: 14px;
  }
}
</style>
